<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <b>{{ 'title.forceNewPassword'.translate() }}</b>
    </HeaderCard>

    <v-card-text style="padding-top: 0; padding-bottom: 0">
      <v-form
        ref="form"
        v-model="valid"
        autocomplete="off"
      >
        <IgnoreAutoFill />

        <v-layout
          wrap
          style="padding: 12px 0"
        >
          <v-flex xs12>
            <FieldReadOnly
              icon="mdi-shield-account"
              label="field.username"
              v-model="username"
            />
          </v-flex>

          <v-flex xs12>
            <FieldPassword
              label="field.newPassword"
              v-model="password"
              autofocus
              @on-enter="changePassword"
            />
          </v-flex>

          <v-flex xs12>
            <FieldPassword
              label="field.confirmPassword"
              v-model="confirmPassword"
              :rules="[
                v => !!v || 'required.password'.translate(),
                v => String(v || '').length > 6 || 'required.length'.translate({ length: 7 }),
                v => v === password || 'invalid.password'.translate(),
              ]"
              @on-enter="changePassword"
            />
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>

    <Message
      type="error"
      icon="mdi-cloud-alert"
      :show="showError"
      :message="auth.error"
    />

    <v-divider />

    <v-card-actions style="padding-top: 0.7em; padding-bottom: 0.7em;">
      <v-layout
        wrap
        justify-space-around
      >
        <v-btn
          text
          small
          color="primary"
          :to="linkLogin"
        >
          {{ 'btn.back'.translate() }}
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          :disabled="!valid"
          @click="changePassword"
          :loading="loading"
        >
          {{ 'btn.access'.translate() }}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/components/Message'
import HeaderCard from '@/components/HeaderCard'
import FieldReadOnly from '@/components/fields/FieldReadOnly'
import FieldPassword from '@/components/fields/FieldPassword'
import { ROUTE_AUTH_LOGIN, ACTION_USER_FORCE_NEW_PASSWORD } from '@/constants'
import IgnoreAutoFill from './IgnoreAutoFill'

export default {
  components: {
    Message,
    HeaderCard,

    FieldReadOnly,
    FieldPassword,
    IgnoreAutoFill
  },

  data () {
    return {
      valid: true,
      loading: false,
      showError: false,

      password: null,
      confirmPassword: null
    }
  },

  methods: {
    changePassword () {
      if (!this.valid) return false
      this.loading = true
      const { password } = this
      this.$store.dispatch(ACTION_USER_FORCE_NEW_PASSWORD, { password })
    }
  },

  computed: {
    linkLogin: () => ({ name: ROUTE_AUTH_LOGIN }),

    ...mapState({
      auth: ({ auth }) => ({ error: auth.error, loading: auth.loading }),
      username: ({ auth }) => {
        if (!auth.userCognito) return ''
        if (!auth.userCognito.challengeParam) return ''
        if (!auth.userCognito.challengeParam.userAttributes) return ''
        return auth.userCognito.challengeParam.userAttributes.email
      }
    })
  },

  watch: {
    password () {
      this.showError = false
    },

    confirmPassword () {
      this.showError = false
    },

    'auth.loading': function (loading) {
      if (this.loading !== loading) this.loading = loading
    },

    'auth.error': function (error) {
      this.showError = Boolean(error)
    }
  }
}
</script>
